html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
}

.example-spacer {
  flex: 1 1 auto;
}

cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: -18 !important;
}
 
.form-control:focus {
  box-shadow: none !important; /* Remove the focus outline */
}

.btn:focus {
  box-shadow: none !important; /* Remove the focus outline */
}
 
.form-control:focus {
  box-shadow: none !important; /* Remove the focus outline */
}

.btn:focus {
  box-shadow: none !important; /* Remove the focus outline */
}


// .mat-toolbar.navbar.mat-primary {
//   background: #ffffff !important;
//   color: #000;
//   z-index: 4 !important;
//   border-bottom: 1px solid #ededed !important;
//   color: #6d768b !important;
//   position: fixed !important;
//   top: 0;
// }

.mat-toolbar.navbar.mat-primary {
  background: #4870FF;
  color: #000;
  z-index: 4 !important;
  border-bottom: 1px solid #ededed !important;
  color: #6d768b !important;
  position: fixed !important;
  top: 0;
  width: 100%;
}

.mat-drawer {
  color: #fff;
  // background: #3030cf;
}

/* .mat-drawer-backdrop.mat-drawer-shown {
    background-color: #f5fafe !important;
} */

.mat-drawer-container {
  color: #525252;
}

// .mat-sidebar-icon {
//   padding: 0px;
//   margin: 0px 7px 0px 0px;
//   margin: 0px;
//   text-align: center;
//   position: relative;
//   left: 5px;
// }

.mat-sidebar-icon {
  padding: 0px;
  // margin: 0px 7px 0px 0px;
  margin: 0px;
  text-align: center;
  position: relative;
  left: -12px;
}
// .header-logo {
//   width: 150px;
//   height: 61px;
//   position: relative;
//   left: 38px;
//   margin-bottom: 10px;
// }


// .header-logo {
//   width: 188px;
//   height: 45px;
//   position: relative;
//   left: 49px;
//   margin-bottom: 10px;
// }

.header-logo {
  width: 182px;
  height: 47px;
  position: relative;
  left: 103px;
  margin-bottom: 10px;
}
mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  margin: 0px 0px 0px 26px;
  border: none;
  background-color: #fff !important;
  color: #000 !important;
}

.mat-button-toggle-appearance-standard {
  color: #000 !important;
  background: none !important;
}

.subheader-content {
  height: 54px;
  float: left;
  background-color: #f5f7f9;
  /* border-bottom: 1px solid #cfd7df; */
  padding-left: 0px;
  padding-right: 0px;
  width: 108%;
  position: relative;
}

.card {
  // border: none;
  // border-radius: 5px;
  // box-shadow: 3px 2px 12px #efefef;
}

.card-new {
  // position: relative;
  // top: 117px;
  margin: 15px;
  border-radius: 5px;
  border: none;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
.fc .fc-toolbar-title {
  color: #3f51b5!important;
  margin:10px 10px 0px 10px!important;
  white-space: nowrap!important;
}
.fc .fc-col-header-cell-cushion{
  color: black!important;
}
.fc .fc-daygrid-day-number{
  color: black!important;
}
.fc .fc-button {
  padding: 4px 7px !important;
  margin-top:10px!important;
}
.fc .fc-button .fc-icon {
  font-size: 18px!important;
}


@media screen and (max-width: 991px) {
  .mat-drawer-content.responsive-content {
    margin-left: 0px !important;
  }
}

@media screen and (min-width: 991px) {
  // .table-responsive {
  //     display: revert!important;
  // }
}

.example-container[_ngcontent-hhl-c102] {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.sub-header {
  background: #ffffff !important;
  border-radius: 0px;
  border: none;
  position: fixed;
  top: 64px;
  float: left;
  width: 100%;
  z-index: 10;
  padding: 3px 0px;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
  padding-left: 19px;
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: #000;
  background-color: #fff;
  font-weight: normal;
  font-size: 16px;
  font-weight: 610;
  line-height: 28px !important;
  padding: 0px 7px !important;
  font-family: 'Mulish', sans-serif;
}

.sub-header-btn {
  position: relative;
  top: -9px;
}

.mat-card {
  background: #fff;
  color: #000;
  border: 1px solid #ddd;
  box-shadow: none !important;
  margin: 13px;
  /*
    position: relative;
    top: 117px; */
}

.mat-paginator {
  background: #fff;
}

// .t-head th {
//     font-weight: normal;
//     background: #f7f7f7;
//     border: none;
// }

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: #000 !important;
}

.mat-menu-panel {
  background: #fff;
  min-height: 39px !important;
  // margin-left: -51px;
  margin-left: -62px;   //:::A
  transform-origin: right top !important;   //:::A
}

.mat-menu-item {
  // color: #d7d7d7;
  line-height: 12px !important;
  height: 35px !important;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon,
.mat-icon {
  // color: #8b8b8b !important;
}

button:focus {
  outline: none !important;
}

.mat-menu-item .mat-icon {
  margin-right: 4px !important;
  vertical-align: middle;
}

mat-icon.mat-icon {
  font-size: 18px;
}

.mat-menu-item {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: none !important;
  height: none !important;
  padding: 7px 15px 7px 15px !important;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 10px;
  text-decoration: none;
}

// table.table-border-new {
//     border-left: 1px solid #ddd;
//     border-right: 1px solid #ddd;
//     border-bottom: 1px solid #ddd;
// }

// .table thead th {
//     vertical-align: middle;
//     border-bottom: none !important;
// }

// .table td {
//     padding: .75rem;
//     vertical-align: middle !important;
//     border-bottom: 1px solid #dee2e6 !important
// }

.mat-raised-button:not([class*="mat-elevation-z"]) {
  border: 1px solid #756d6d70 !important;
  box-shadow: none;
  transition: none;
}

a {
  text-decoration: none !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #009ef7 !important;
  border: 1px solid #009ef7 !important;
  color: #fff !important;
}

span.star {
  color: #ff0000;
  font-size: 16px;
}

.header-menu {
  color: #fffbfb;
  font-size: 30px !important;
}

// img.brandB {
//   width: 35px;
//   height: 35px;
//   text-align: center;
//   left: 16px;
//   position: relative;
// }

.list-group {
  padding-top: 20px;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-list-base .mat-list-item {
  // color: #e5e3e3;
  height: 43px !important;
}

span.mat-list-item-content mat-icon {
  // color: #e5e3e3 !important;
}

// .mat-list-item span:hover {
//   // color: #fff !important;
//   background: #23232f00 !important;
// }

// input.input-header {
//   border: 1px solid #ddd;
//   font-size: 14px;
//   /* height: 21px; */
//   margin-left: 9px;
//   padding: 0px 10px;
//   border-radius: 3px;
// }

// input.input-header:focus-visible {
//   outline: none;
// }

// input,
// select {
//   border: 1px solid #dee2e6 !important;
// }

.header-buttons a {
  color: #817a7a;
}

a.sidebar-icons:hover {
  // color: #fff !important;
  background: #8b8b8b00 !important;
}

a.sidebar-icons:hover .mat-sidebar-icon {
  color: #009ef7 !important;
}

a.header-common-buttons,
a.header-common-buttons:hover {
  padding: 5px 10px 5px;
  position: relative;
  top: -3px;
  margin-left: 13px;
}

.header-buttons {
  display: flex;
  right: 20px;
  text-align: right;
  float: right;
  position: fixed;
}

.header-buttons a mat-icon {
  color: #b9c2dd !important;
  position: absolute;
  top: 10px;
  right: 0px;
  font-size: 23px;
}

.header-buttons a:hover mat-icon {
  color: #96acec !important;
  /* position: relative;
    top: 10px; */
  font-size: 23px;
}

@media (max-width: 599px) {
  .buttons-list,
  .h5.sub-content {
    display: none;
  }
  .sub-header {
    top: 56px;
    min-height: 48px;
  }
}

.sub-header-buttons {
  display: flex;
  position: fixed;
  right: 20px;
  margin-top: 5px;
  justify-content: end;
}


// .sub-content {
//   position: relative;
//   top: 6px;
//   color: #181c32;
//   font-weight: 500;
//   font-size: 1.25rem !important;
// }

.sub-content {
  position: relative;
  top: 6px;
  color: #181c32;
  font-weight: 500;
  font-size: -0.75rem !important;
}

mat-icon.icon-warning,
.text-warning {
  color: #ffc700 !important;
}

.text-primary,
mat-icon.icon-primary {
  color: #009ef7 !important;
}

.text-danger,
mat-icon.icon-danger {
  color: #ce1241 !important;
  font-weight: 500;
}

.text-danger{
  color: #FF0000 !important; /* Lighter shade of red */
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.text-danger2,
mat-icon.icon-danger {
  color: #FF0000 !important;
  font-weight: bold; /* Add this line to make the text bold */
}

.text-success,
mat-icon.icon-success {
  color: #50cd89 !important;
  font-weight: 500;
  font-family: 'Mulish', sans-serif;
}

.bg-light-primary {
  background-color: #f1faff;
}

.bg-light-danger {
  background-color: #fff5f8;
}

.bg-light-success {
  background-color: #e8fff3;
}

button.search-btn {
  height: 32px;
  position: relative;
  top: 3px;
}

.common-content {
  float: left;
  width: 100%;
  position: relative;
  top: 110px;
}

.app-content {
  background-color: #f5f5f5 !important;
  height: 100vh !important;
}

.footer {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 10px;
  text-align: center;
}

.mat-drawer-content {
  overflow-x: hidden !important;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.mat-drawer-content::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.mat-drawer-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9a9a9a;
}

.mat-drawer-content::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #041722;
}

select.report-filter {
  display: block;
  width: 100%;
  // height: calc(1.5em + 0.75rem + 2px);
  padding: 0.275rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  // line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 30px;
  margin: 0px 35px 0px 0px;
}

//Form Controls Start
.form-control.form-control-solid {
  // background-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease;
  border: 2px solid #dee2e6 ;
}

.form-control {
  // color: var(--Dull-Text-color, #969696);
  font-family: 'Mulish', sans-serif;
  font-size: 15px;
  font-style: 2;
  font-weight: 500;
  line-height: normal;
  display: block;
  // width: 250px;
  // font-size: 14px;
  // font-weight: 400;
  // line-height: 1.5;
  // color: #5e6278;
  background-color: none !important;
  background-clip: padding-box;
  border: 2px solid #dee2e6 ;
  // -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  height: 38px;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown.show > .form-control.form-control-solid,
.form-control.form-control-solid:active,
.form-control.form-control-solid:focus,
.form-select.form-select-solid:focus-visible {
  // background-color: #eef3f7;
  border: 2px solid #dee2e6 !important;
  color: #5e6278;
  transition: color 0.2s ease;
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 0%) !important;
  outline: 0 !important;
}

//Form Controls End
//Select field design Start
.form-select.form-select-solid {
  // background-color: #f5f8fa;
  border: 2px solid #dee2e6 !important;
  color: #5e6278;
  transition: color 0.2s ease;
}

.form-select {
  display: block;
  width: 100%;
  padding: 15px;
  border: 1px solid #e4e6ef;
  box-shadow: none !important;
  // border-radius: 0.625rem;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  height: 38px;
  font-size: 14px !important;
}

.form-select-lg {
  padding-top: 0.525rem;
  padding-bottom: 0.525rem;
  padding-left: 1rem;
  border-radius: 5px;
  font-size: 14px !important;
  border: 2px solid  #dee2e6;

}
.form-control-lg {
  font-size: 14px !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef7a;
}
.example-input {
  height: 38px !important;
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7pxrgba (0, 0, 0, 0.2),
    0px 24px 38px 3pxrgba (0, 0, 0, 0.14), 0px 9px 46px 8pxrgba (0, 0, 0, 0.12);
  background: #fff !important;
  color: #000 !important;
  // padding: 12px !important;
  overflow: hidden !important;
}

//Select field design End
.ngx-pagination .current {
  // padding: 0.1875rem 0.625rem;
  padding: 10px 15px 10px 15px;
  background: #2199e8;
  color: #fefefe;
  cursor: default;
  border-radius: 50px !important;
}
.ngx-pagination .current:hover {
  background: #009ef7;
  border-radius: 50px;
  border: transparent;
  text-decoration: none;
  // padding: 0.1875rem 0.625rem;
  // padding: 10px 15px 10px 15px;
  cursor: default;
}
/* Styles for all screens */
.some-element {
  /* Your styles here */
}

/* Media query for mobile screens */
@media only screen and (max-width: 767px) {
  /* Styles for mobile screens */
  .ngx-pagination .current {
    padding: none !important; /* Set width to 100% for mobile screens */
    /* Additional styles as needed */
  }
}


span.span-error {
  color: red;
  padding: 6px 2px 3px 0px;
  margin: 6px 3px 2px 3px;
}

textarea {
  // min-height: 70px !important;
  /* max-height: 100px; */
  // border: 1px solid #dee2e6 !important;
}

.controls-list button {
  padding: 10px !important;
  margin: 0px !important;
  line-height: 20px !important;
  color: #000;
  height: 35px;
}

p.show-count {
  position: relative;
  top: 6px;
}

.active-status {
  width: 100px;
}

.toast-msg {
  width: auto;
  float: left;
  position: fixed;
  top: 65px;
  z-index: 9999;
  float: right;
  right: 22px;
  text-align: left;
}

.alert-success {
  color: #155724;
  background-color: #94cba1;
  border-color: #94cba1;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 0px !important;
  padding: 1px !important;
  /* margin-right: 10px; */
}

span.mat-button-toggle-label-content mat-icon {
  line-height: 28px;
}

.mat-button-toggle-label-content {
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  /* line-height: 36px; */
  padding: 0 16px;
  position: relative;
}

.mat-button-toggle-label-content {
  line-height: 0 !important;
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: none !important;
}

mat-button-toggle.grid-icon {
  border: 1px solid #d9d3d3 !important;
  border-right: 0px !important;
}

mat-button-toggle.list-icon {
  border: 1px solid #d9d3d3 !important;
  border-right: 0px !important;
  border-left: 0px !important;
}

mat-button-toggle.view-icon {
  border: 1px solid #d9d3d3 !important;
  border-left: 0px !important;
}

.active-list mat-button-toggle.active-list-btn,
.active-list mat-button-toggle.active-list-btn mat-icon {
  background: #008cd1 !important;
  // color: #fff !important;
}

mat-button-toggle.mat-button-toggle:hover {
  background: #f3f7fd;
}

// mat-button-toggle.mat-button-toggle.active-list-btn {
//   background: #008cd1;
//   border: 1px solid #008cd1 !important;
// }

.mat-button-toggle-label-content {
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
  line-height: 36px;
  padding: 0 5px !important;
  position: relative;
}

mat-button-toggle-group {
  border-radius: 0px !important;
}

.spinner-loading {
  float: left;
  width: 100%;
  margin: 0 auto !important;
  text-align: center;
}

.spinner-loading app-root.spin-content {
  text-align: center;
}

span.spin-content {
  text-align: center;
  margin: 0 auto;
  float: left;
  width: 100%;
  position: relative;
  top: 50%;
}

.valid-msg.ng-star-inserted {
  margin: 5px 0px 0px 2px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

/* .table-responsive {
    display: block!important;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}
*/

.table-responsive::-webkit-scrollbar {
  // height: 2px;
  height: 4px;   //:::A
  background-color: #f5f5f5;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #918787 !important;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #d9d9d9;
}

.mat-drawer-container {
  background-color: #30303000 !important;
}

.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
  vertical-align: middle;
  color: #78787a;
}

ul.sub-menus {
  // background: #ffffff1f;
  // list-style:none;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
// ul.sub-menus li a.mat-list-item:hover {
//   background: #0000000a;
//   border-radius: 10px;
//   // color: #e5e3e3;
// }
// ul.sub-menus li a.mat-list-item.active {
//   background: #ffffff1f;
//   border-radius: 10px;
//   color: #e5e3e3;
// }
ul.sub-menus li a span {
  // width: 30px;
}

ul.sub-menus li {
  padding: 10px;
  list-style: unset;
  // color: #e5e3e3;
  color: #424242;
}
// .mat-checkbox mat-accent ng-valid ng-dirty ng-touched mat-checkbox-checked{
//   background: #1A83FF ;

// }
.mat-pseudo-checkbox{
  // background: #1A83FF;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1),background-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  // background: #1A83FF ;
}
mat-list-item.mat-list-item.mat-focus-indicator.active {
  // background: #6e6e6e;
  border-radius: 10px;
}
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// .mat-primary .mat-pseudo-checkbox-checked {
//   background-color: #1A83FF!important; /* Green */
// }


// mat-list-item.mat-list-item.mat-focus-indicator.active a,
// mat-list-item.mat-list-item.mat-focus-indicator.active
//   a:hover.mat-list-item
//   span:hover {
//   color: #6e6e6e !important;
// }

// .mat-select-placeholder {
//   color: #787676 !important;
// }
// .mat-select-value {
//   color: #787676 !important;
// }
// @import '@angular/material/prebuilt-themes/pink-bluegrey.css';
.mat-autocomplete-panel {
  background: #ffffff !important;
  color: #060606 !important;
}
.mat-option {
  color: #080808 !important;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #ffffff !important;
}

ul.auto-complete-country li {
  list-style: none;
  padding: 8px 5px;
  position: relative;
  left: 0px;
  background: #f4f6f9;
  border-bottom: 1px solid #dee4e9;
}

ul.auto-complete-country {
  // margin: 0;
  // position: relative;
  // left: 0px;
  // padding: 0px;

  // ::A
    margin: 0;
    position: absolute;
    /* left: 0px; */
    padding: 0px;
    /* z-index: 99; */
    width: 90%;
    z-index: 99;  
}

ul.auto-complete-country li:hover {
  background: #fff !important;
  cursor: default;
  border: 1px solid #ddd;
}

///Loader
.spinner-border {
  color: #009ef7;
  position: absolute;
  z-index: 1;
  top: 70%;
  right: 48%;
}
// .profile-buttons :host ::ng-deep .mat-menu-item{
//     line-height: 12px !important;
//     height: 35px !important;
//   }

.ngx-pagination {
  display: flex;
}

// .btn[_ngcontent-nsf-c532] {
//   background: #03c9d7;
//   color: white;
//   font-weight: 550;
//   box-shadow: 0 0 rgba(0, 0, 0, 0.2), 0 0 rgba(0, 0, 0, 0.1411764706),
//     0 0 rgba(0, 0, 0, 0.1215686275);
//   font-size: 14px;
// }

// .btn {
//   /* background: #03c9d7; */
//   border-radius: 5px;
//   background: #1A83FF;
//   box-shadow: 0px 4px 15px 0px rgba(26, 131, 255, 0.15);
//   color: white;
//   font-weight: 550;
//   box-shadow: 0 0 rgba(0, 0, 0, 0.2), 0 0 rgba(0, 0, 0, 0.1411764706), 0 0 rgba(0, 0, 0, 0.1215686275);
//   font-size: 14px;
// }
.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
  vertical-align: middle;
  // color: #fff;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon,
.mat-icon {
  // color: whitesmoke !important;
  // color: black !important;
}
@media screen and (max-width: 360px) {
  .ngx-pagination .current{
    height: 28px !important;
  }
}
.mat-button .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > * {
  vertical-align: middle;
  color: currentcolor;
}

.search-menu[_ngcontent-ktf-c429] {
  font-size: 13px;
  border-radius: 20px !important;
  width: 300px;
  height: 38px;
  border: 2px solid #03c9d7;
  background-color: transparent;
  transition: box-shadow 0.15s ease;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}


.search-border{
  border: 2px solid #03c9d7;
}
// .table th {
//   color: #fff;
//   font-family: Mulish;
//   font-size: 15px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   letter-spacing: 0.075px;
//   // padding: 0.5rem;
//   vertical-align: middle;
//   font-family: Mulish;
//   // border-bottom: 1px;
//   // border-bottom-width: 1px;
//   // border-bottom-style: solid;
//   // font-size: 14px;
//   color: #fff;
//   border-bottom-color: rgba(0, 0, 0, 0.1215686275);
//   border-top: 0px;
//   background: #708fff;
//   text-align: center;
// }

// .table td {
//   color: var(--Text-Color---Non-Select, #424242);
//   text-align: center;
//   font-family: Mulish;
//   font-size: 15px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: normal;
//   letter-spacing: 0.075px;

//   // padding: 0.5rem;
//   // vertical-align: middle;
//   // border-bottom: 1px;
//   // border-bottom-width: 1px;
//   // border-bottom-style: solid;
//   // font-size: 15px;
//   // color: inherit;
//   // font-weight: 500;
//   // border-bottom-color: #0000001f;
//   // border-top: 0px;
// }
// .table td,
// .table th {
//   padding: 0.5rem;
//   vertical-align: middle;
//   border-top: 1px solid #dee2e6;
// }


mat-list-item.mat-list-item.mat-focus-indicator.active a, mat-list-item.mat-list-item.mat-focus-indicator.active a:hover.mat-list-item span:hover {
  color: #fff !important;
}

mat-list-item.mat-list-item.mat-focus-indicator.active mat-icon{
  color: #fff !important;
}

// mat-list-item .mat-icon.active {
//   display: inline-block;
//   fill: currentColor;
//   margin-left: -16px;
//   color: #fff !important;
// }

.ngx-pagination a, .ngx-pagination button {
  color: #0a0a0a;
  display: block;
  padding: .1875rem .625rem;
  border-radius: 0;
  font-size: 15px;
  font-family: 'Mulish', sans-serif;


}
@media only screen and (max-width: 767px) {
  /* Styles for mobile screens */
  .ngx-pagination a {
    font-size: 12px !important;
    padding: 0 !important;
  
  }
  .ngx-pagination{
    margin-left: -16px !important;
    margin-top: 13px !important;
  
  }
}

// .mat-menu-item .mat-icon-no-color, .mat-menu-submenu-icon, .mat-icon {
//   color: #fff!important;
// }

::-webkit-scrollbar {
  width: 0.5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.mat-menu-item[disabled], .mat-menu-item[disabled] .mat-menu-submenu-icon, .mat-menu-item[disabled] .mat-icon-no-color {
  //  color: rgba(0,0,0,.38); 
}


mat-button-toggle.mat-button-toggle.active-list-btn {
  // background: #008cd1;
  border: 1px solid #4870FF !important;
  background: #4870FF;
  color: #fff;
}

.active-list mat-button-toggle.active-list-btn, .active-list mat-button-toggle.active-list-btn mat-icon {
  background: #4870FF !important;
  color: #fff !important;
}

// img.brandB {
//   width: 35px;
//   height: 35px;
//   text-align: center;
//   left: 31px;
//   position: relative;
// }
img.brandB {
  width: 91px;
  height: 91px;
  text-align: center;
  left: 6px;
  position: relative;
}


.mat-datepicker-content-touch .mat-datepicker-content-container {
  width: 1vh !important;
  height: 43vh !important;
}

.mat-datepicker-content-touch .mat-datepicker-content-container {
  width: 1vh !important;
   height: 13vh !important; 
}


.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate{
  background: #1a83ff;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background: #1a83ff;
}

.header-logo2 {
  width: 182px;
  height: 37px !important;
  position: relative;
  left: 18px !important;
  margin-bottom: 10px;
}

img.brand {
  vertical-align: middle;
  border-style: none;
  margin-left: 10px;
  height: 43px !important;
  width: 166px !important;
  margin-bottom: 4px;
}

.mat-list-item.active {
  border-radius: 10px;
  background: #365eea !important;
  padding: 0 6px;
  margin-left: 7px;
  color: #fff !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // width: 200px !important;
  min-width: 174px !important;
  // min-width: -webkit-fill-available !important;
  // min-width: max-content;
}

.form-control:focus {
  box-shadow: none !important; /* Remove the focus outline */
}

.btn:focus {
  box-shadow: none !important; /* Remove the focus outline */
}

input[type="search"] {
  cursor: pointer !important;
}

/* Add this to your global styles.css or styles.scss */
// .no-background-color .mat-checkbox-inner-container {
//   background-color: red !important;
//   height: 200px;
// }
// .no-background-color .mat-checkbox-frame {
//   border-color: black !important;
//   height: 200px;

// }

.btn-clear {


  padding: 7px 12px 7px 12px;
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.075px;
  height: 35px;
  line-height: 33px !important;
}

.mat-dialog-container {
  display: block;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto !important;
  outline: 0;
  width: 100%;
  height: auto;
  min-height: inherit;
  max-height: inherit;
}

.select_list {
  padding: 3px 6px 3px 6px;
  // border-radius: 20px;
  font-size: 12px;
}
.new-select_list option {
  min-height: 1px !important;
  font-size: 12px;
  border-radius: 20px;
}

.listDesign {
  border-radius: 5px;
  border: 1px solid #999999;
  background: #fff;
}

.mat-select-search-inside-mat-option .mat-select-search-input:focus {
  padding-top: 0;
  padding-bottom: 0;
  height: 3em;
  line-height: 3em;
  border: white !important;
  box-shadow: none !important;
}

// .mat-select-search-input {
//   padding: 16px 44px 16px 16px;
//   box-sizing: border-box;
//   width: 100%;
//   border: none !important;
//   box-shadow: none !important;
// }

.mat-select-search-input {
  padding: 16px 44px 16px 16px;
  box-sizing: border-box;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
  box-shadow: none; /* Remove the focus outline */
  border: none;     /* Remove the border */
  outline: none; 
  // min-height: 38px !important;
  // max-height: 38px !important;
  // height: 38px !important;
  // position: relative !important;
  // margin-top: 5px !important;
 

}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{
  background: #405bff !important;
}


.mat-select-disabled .mat-select-value {
  color: black !important; 
}



:root {
  --ck-color-grid-tile-size: 24px !important;
  --ck-color-color-grid-check-icon: #405bff !important;
}